import React from "react"
import { useForm } from "react-hook-form"
import { FormType } from "../../types"
import { trimmedGaCookieValue } from "../../../utils/look-up-ga-cookie"
import persistQueryParams from "../../../utils/persist-query-params"

type FormProps = {
  form: FormType
  [key: string]: any
}

type FormData = {
  zipCode: string
}

const ZipForm = ({ form, ...props }: FormProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const zipCodeClasses = `mb-1 block w-full bg-inputBackground ${errors.zipCode ? `border-red-500` : ``}`
  const buttonClasses = `p-3 min-w-50 button`

  const onSubmit = (data: FormData) => {
    // GA event logging
    if (typeof marketingDataLayer !== `undefined`) {
      marketingDataLayer?.push({
        event: `gaForm`,
        eventCategory: `Form`,
        eventAction: `zip-form`,
        eventLabel: location.pathname
      });
    }

    // Tealium event logging
    if (typeof utag !== `undefined`) {
      console.log(`Tealium is loaded: ${trimmedGaCookieValue()}`)
      
      utag.link({
        "customer_birthdate"     : "",                  //Required
        "customer_email"         : "",                  //Required
        "customer_first_name"    : "",                  //Required
        "customer_id"            : trimmedGaCookieValue(), //Required (RTRIM value of _ga Cookie)
        "customer_last_name"     : "",                  //Required
        "customer_name"          : "",                  //Required
        "customer_phone_number"  : "",                  //Required
        "customer_postal_code"   : `${data.zipCode}`,   //Required
        "customer_state"         : "",                  //Required
        "customer_type"          : "",                  //Required
        "form_id"                : "",                  //Required
        "form_name"              : "Zip Form",          //Required
        "tealium_event"          : "form_complete"      //Required
      });
    }
    else {
      console.log(`Tealium is NOT loaded: ${trimmedGaCookieValue()}`)
    }

    const search = persistQueryParams({ search: `zipCode=${data.zipCode}` }, location)
    location.assign(`${form.destination}?${search}`)
  }

  // On load, focus the form
  React.useEffect(() => {
    
  }, [])

  return (
    <form id="zip-form" onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register("zipCode", { required: true, maxLength: 5, minLength: 4 })}
        type="number"
        className={zipCodeClasses}
        placeholder="Enter Zip Code"
        autoComplete="postal-code"
        autoFocus
      />

      <div className="text-center mt-5">
        {Object.keys(errors).length > 0 && (
          <div className="bg-red-400 p-3 my-10 text-white rounded">A 4 or 5 digit zip code is required</div>
        )}

        <button type="submit" className={buttonClasses}>
          {form.submitText || `Submit`}
        </button>
      </div>
    </form>
  )
}

export default ZipForm
